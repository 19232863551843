import React, { FC } from 'react';
import { GetServerSideProps } from 'next';
import dynamic from 'next/dynamic';
import type { MDXRemoteSerializeResult } from 'next-mdx-remote/dist/types';

import config from 'config';
import { parseMarkdown } from 'helpers/parseMarkdown';
import CMS from 'utils/cms/strapi';
import trustedByQuery from 'utils/queries/trustedBy';
import reviewsSliderQuery from 'utils/queries/reviewsSlider';
import { articlesQuery } from 'utils/queries/articles';
import { contactFormQuery } from 'utils/queries/contactForm';
import menuQuery from 'utils/queries/menu';
import homesQuery from 'utils/queries/home';
import footerQuery from 'utils/queries/footer';
import popupWidgetQuery from 'utils/queries/popupWidgetQuery';
import { CommonPageProps } from 'utils/types/CommonPageProps';

import { MainSlider, MainSliderProps } from 'components/molecules/MainSlider/MainSlider';
import { CountersSectionProps } from 'components/CountersSection/CountersSection';
import { CoreTechProps } from 'components/CoreTechnologies/CoreTechnologies';
import { OurArtsProps } from 'components/OurArts/OurArts';
import { ImageRightSection, ImageRightSectionProps } from 'components/ImageRight/ImageRightSection';
import { ImageLeftSection } from 'components/ImageLeft/ImageLeftSection';
import { TrustedBy, TrustedByProps } from 'components/organisms/TrustedBy/TrustedBy';
import { CoreTeamProps } from 'components/CoreTeam/CoreTeam';
import { ContactFormProps } from 'components/ContactForm/ContactForm';
import Hexagon from 'components/atoms/Geometric/Hexagon/Hexagon';
import { MetaTag } from 'components/MetaTags/MetaTags';
import { ReviewsProps } from 'components/molecules/Reviews/Reviews';
import Container from 'components/atoms/Container/Container';
import SliderBoxes, { SliderBoxProps } from 'components/molecules/SliderBoxes/SliderBoxes';
import { ArticleTileProps } from 'components/molecules/ArticleTile/ArticleTile';
import { StyledTopSpacingWrapper } from 'components/ContactForm/ContactForm.styles';
import { getParsedFooterProps } from 'components/Footer/Footer.utils';
import { LatestArticlesProps } from 'components/LatestArticles/LatestArticles';
import { PopupWidgetProps } from 'components/ContactWidget/PopupWidget';
import { ClutchProps } from 'utils/types/Clutch';
import clutchWidgetQuery from 'utils/queries/clutchWidget';
import logoLinkQuery from 'utils/queries/logoLink';

const CountersSection = dynamic(() =>
  import('components/CountersSection/CountersSection').then((mod) => mod.CountersSection)
);
const CoreTechnologies = dynamic(() =>
  import('components/CoreTechnologies/CoreTechnologies').then((mod) => mod.CoreTechnologies)
);
const CoreTeam = dynamic(() => import('components/CoreTeam/CoreTeam').then((mod) => mod.CoreTeam));
const Reviews = dynamic(() => import('components/molecules/Reviews/Reviews'));
const OurArts = dynamic(() => import('components/OurArts/OurArts').then((mod) => mod.OurArts));
const ContactForm = dynamic(() => import('components/ContactForm/ContactForm').then((mod) => mod.ContactForm));

const { ROUTES } = config;

type HomesProps = {
  page: {
    metaTags: { meta: Array<MetaTag> };
    mainSlider: MainSliderProps;
    serviceBox: SliderBoxProps['serviceBoxes'];
    supergraphicTop: {
      title: MDXRemoteSerializeResult;
      button: {
        path: string;
        name: string;
      };
      image: {
        src: string;
        alt: string;
      };
    };
    counters: CountersSectionProps;
    coreTeam: CoreTeamProps;
    coreTechnologies: CoreTechProps;
    textAndImage: ImageRightSectionProps;
    ourArts: OurArtsProps;
    latestArticles: LatestArticlesProps;
  };
  trustedBy: TrustedByProps;
  reviewsSlider: ReviewsProps;
  articles: Array<ArticleTileProps>;
  contactForm: ContactFormProps;
  popupWidget: PopupWidgetProps;
  clutchWidget: ClutchProps;
} & CommonPageProps;

export const DEFAULT_START_VALUE = 0;
export const DEFAULT_LIMIT_VALUE = 4;

export const getServerSideProps: GetServerSideProps<HomesProps> = async ({ locale, preview = false }) => {
  const page = new CMS([
    () => homesQuery(locale),
    () => trustedByQuery(locale),
    () => reviewsSliderQuery(locale),
    () =>
      articlesQuery({
        start: DEFAULT_START_VALUE,
        limit: DEFAULT_LIMIT_VALUE,
        locale: locale
      }),
    () => contactFormQuery(locale),
    () => menuQuery(locale),
    () => footerQuery(locale),
    () => clutchWidgetQuery(locale),
    () => popupWidgetQuery(locale),
    () => logoLinkQuery(locale)
  ]);

  const [
    homesResult,
    trustedByResult,
    reviewsSliderResult,
    articlesResult,
    contactFormResult,
    menuResult,
    footerResult,
    clutchWidgetResult,
    popupWidgetResult,
    logoLinkResult
  ] = await page.getContent();

  const homes = homesResult.data.homes[0];
  const reviewsSlider = reviewsSliderResult.data.reviewsSlider;
  const articles = articlesResult.data.articles;
  const clutch = clutchWidgetResult.data.clutchWidget;
  const logo = logoLinkResult.data.logoLink;

  return {
    props: {
      page: {
        ...homes,
        mainSlider: {
          ...homes.mainSlider,
          title: await parseMarkdown(homes.mainSlider.title),
          subtitle: await parseMarkdown(homes.mainSlider.subTitle),
          description: await parseMarkdown(homes.mainSlider.description)
        },
        supergraphicTop: {
          ...homes.supergraphicTop,
          image: {
            src: homes.supergraphicTop.image.image[0].url,
            alt: homes.supergraphicTop.image.alt
          },
          title: await parseMarkdown(homes.supergraphicTop.title)
        },
        textAndImage: {
          ...homes.textAndImage,
          image: {
            src: homes.textAndImage.image.image[0].url,
            alt: homes.textAndImage.image.alt
          },
          title: await parseMarkdown(homes.textAndImage.title),
          description: await parseMarkdown(homes.textAndImage.description)
        },
        ourArts: {
          ...homes.ourArts,
          description: await parseMarkdown(homes.ourArts.description)
        },
        coreTeam: {
          ...homes.coreTeam,
          description: await parseMarkdown(homes.coreTeam.description)
        }
      },
      trustedBy: trustedByResult.data.trustedBy,
      reviewsSlider: {
        ...reviewsSlider,
        reviews: await Promise.all(
          reviewsSlider.reviews.map(async ({ author, review, position, ...reviewItem }) => ({
            ...reviewItem,
            author: await parseMarkdown(author),
            position: await parseMarkdown(position),
            review: await parseMarkdown(review)
          }))
        )
      },
      articles: await Promise.all(
        articles.map(async (article) => ({
          ...article,
          href: `${ROUTES.BLOG.href}/${article.slug}`,
          readTime: await parseMarkdown(article.readTime)
        }))
      ),
      contactForm: contactFormResult.data.contactForm,
      menuStructure: menuResult.data.menus,
      footerData: {
        ...(await getParsedFooterProps(footerResult.data.footers[0]))
      },
      isPreview: preview,
      clutchWidget: { ...clutch },
      popupWidget: popupWidgetResult.data.popupWidget,
      logo
    }
  };
};

const Home: FC<HomesProps> = ({
  page: {
    mainSlider,
    serviceBox,
    supergraphicTop,
    counters,
    coreTeam,
    coreTechnologies,
    textAndImage,
    ourArts,
    latestArticles
  },
  trustedBy,
  reviewsSlider,
  articles,
  contactForm,
  clutchWidget
}) => (
  <>
    <div style={{ overflow: 'hidden' }}>
      <MainSlider {...mainSlider} isHomePage />
      {serviceBox.length > 0 && <SliderBoxes serviceBoxes={serviceBox} />}
      <ImageLeftSection
        width="150%"
        clutch={clutchWidget}
        fontSize="h4bigger"
        isRelative={false}
        marginTop="200px"
        {...supergraphicTop}
        imageTop="-64px"
        backShapes={
          <>
            <Hexagon width="604px" top="-30px" left="-182px" variant="backgroundLight" zIndex={0} />
          </>
        }
      />
      <CountersSection {...counters} />
      <CoreTeam {...coreTeam} />
      <TrustedBy {...trustedBy} />
      <Container>
        <Reviews {...reviewsSlider} shouldShowTitle={false} clutch={clutchWidget} />
      </Container>
      <CoreTechnologies {...coreTechnologies} {...latestArticles} articles={latestArticles ? articles : null} />
      <ImageRightSection {...textAndImage} />
      <OurArts {...ourArts} />
    </div>
    <StyledTopSpacingWrapper>
      <ContactForm {...contactForm} />
    </StyledTopSpacingWrapper>
  </>
);

export default Home;
